import { Component, InputSignal, input } from '@angular/core';
import { FileUploadControl } from '../../helpers/control.class';
import { FileUploadService } from '../../services/file-upload.service';
import { FileUploadIconComponent } from './file-upload-icon.component';

@Component({
    selector: `file-upload-list-item`,
    template: `
    <file-upload-icon [file]="file()"></file-upload-icon>
    <div class="file-info">
        <span class="file-name">{{ file().name }}</span> ({{ calculateSize( file().size ) }})
    </div>
    <div class="file-buttons">
        <span class="remove-btn" (click)="removeFile(file())">
        <svg viewBox="0 0 96 96">
            <g>
                <path d="M40.5,66.8V39.3c0-0.4-0.1-0.7-0.4-0.9S39.6,38,39.3,38h-2.5c-0.4,0-0.7,0.1-0.9,0.4
                    s-0.4,0.5-0.4,0.9v27.5c0,0.4,0.1,0.7,0.4,0.9s0.5,0.4,0.9,0.4h2.5c0.4,0,0.7-0.1,0.9-0.4S40.5,67.1,40.5,66.8z M50.5,66.8V39.3
                    c0-0.4-0.1-0.7-0.4-0.9S49.6,38,49.3,38h-2.5c-0.4,0-0.7,0.1-0.9,0.4s-0.4,0.5-0.4,0.9v27.5c0,0.4,0.1,0.7,0.4,0.9s0.5,0.4,0.9,0.4
                    h2.5c0.4,0,0.7-0.1,0.9-0.4S50.5,67.1,50.5,66.8z M60.5,66.8V39.3c0-0.4-0.1-0.7-0.4-0.9S59.6,38,59.3,38h-2.5
                    c-0.4,0-0.7,0.1-0.9,0.4s-0.4,0.5-0.4,0.9v27.5c0,0.4,0.1,0.7,0.4,0.9s0.5,0.4,0.9,0.4h2.5c0.4,0,0.7-0.1,0.9-0.4
                    S60.5,67.1,60.5,66.8z M39.3,28h17.5l-1.9-4.6c-0.2-0.2-0.4-0.4-0.7-0.4H41.8c-0.3,0.1-0.5,0.2-0.7,0.4L39.3,28z M75.5,29.3v2.5
                    c0,0.4-0.1,0.7-0.4,0.9S74.6,33,74.3,33h-3.8v37c0,2.2-0.6,4-1.8,5.6S66,78,64.3,78H31.8c-1.7,0-3.2-0.8-4.4-2.3s-1.8-3.4-1.8-5.5
                    V33h-3.8c-0.4,0-0.7-0.1-0.9-0.4s-0.4-0.5-0.4-0.9v-2.5c0-0.4,0.1-0.7,0.4-0.9s0.5-0.4,0.9-0.4h12.1l2.7-6.5c0.4-1,1.1-1.8,2.1-2.5
                    s2-1,3.1-1h12.5c1,0,2.1,0.3,3.1,1s1.7,1.5,2.1,2.5l2.7,6.5h12.1c0.4,0,0.7,0.1,0.9,0.4S75.5,28.9,75.5,29.3z"/>
            </g>
        </svg> <ng-content></ng-content></span>
    </div>
    `,
    styleUrls: [`./file-upload-list-item.component.scss`],
    standalone: true,
    imports: [FileUploadIconComponent]
})
export class FileUploadListItemComponent {

    public index: InputSignal<number> = input.required<number>();

    public file: InputSignal<File> = input.required<File>();

    public control: InputSignal<FileUploadControl> = input.required<FileUploadControl>();

    constructor(private fileUploadService: FileUploadService) {
    }

    public removeFile(file: File): void {
        this.control().removeFile(file);
    }

    public calculateSize(size: number): string {
       return this.fileUploadService.formatSize(size);
    }
}